.input-register-login-facj {
  margin: 2rem 0;
  border: 0;
  border-bottom: solid 3px var(--base_02);
  border-radius: 0;
  display: flex;
  position: relative;
}

.icon-password-facj {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-right: 20px;
}

.container-register-facj {
  padding: 0 6vw;
  text-align: left;
  margin-top: 8vh;
  color: var(--base_02);
}

.navbar-register-facj {
  padding: 2rem 0 5rem 0;
}

.btn-register-facj-1 {
  display: grid;
}
