.footer-style{
    background-color: #324B75;
    padding: 10px;
    margin: 0;
    position: absolute;

}

.text-footer{
    color:#fff;
}

.text-footer:hover{
    color:#DBE3F0;
    border-bottom: 1px solid #dbe3f0a6;
 
}
 

