.tooltip-margin-left {
  margin-left: 0.5rem;
}

.orange-icon {
  color: #ffa500;
  font-size: 1.2rem;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background-color: white;
  box-shadow: 0px 0px 4px black;
  opacity: 1;
  color: black;
}
