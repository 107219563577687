.bg-sucesso {
    font-weight: 500;
    padding: 1.2rem;
    background-color:#ffffff;
    border-left: solid 1rem rgb(22, 209, 94);
    border-radius: 12px;
    color: black !important;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: start;
    

   
    -webkit-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    -moz-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
}

.texto-titulo-sucesso {
    color: rgb(28, 175, 85);
    background-color:transparent;
    box-shadow: none;
    font-size: 1.2rem;
    font-weight: 600;

    border: none; 
       
}

.texto-mensagem-sucesso {
    color:  rgb(28, 175, 85);
    text-align: center;
    

}

.bg-alerta {
    font-weight: 500;
    padding: 1.2rem;
    background-color:#ffffff;
    border-left: solid 1rem #F6C12C;
    border-radius: 12px;
    color: black !important;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: start;
    

   
    -webkit-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    -moz-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
}

.texto-titulo-alerta {
    color: #c99508;
    background-color:transparent;
    box-shadow: none;
    font-size: 1.2rem;
    font-weight: 600;

    border: none; 
       
}

.texto-mensagem-alerta {
    color: #c99508;
    text-align: center;
    

}

.bg-erro {
    font-weight: 500;
    padding: 1.2rem;
    background-color:#ffffff;
    border-left: solid 1rem #DC3545;
    border-radius: 12px;
    color: black !important;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: start;
    

   
    -webkit-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    -moz-box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
    box-shadow: 0px 0px 55px -7px rgb(107, 107, 107);
}

.texto-titulo-erro {
    color: #DC3545;
    background-color:transparent;
    box-shadow: none;
    font-size: 1.2rem;
    font-weight: 600;

    border: none; 
       
}

.texto-mensagem-erro {
    color:  #DC3545;
    text-align: center;
    

}





.custom-toast-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Outras propriedades de estilo personalizado se necessário */
  }