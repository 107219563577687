.senha-modal .modal-header {
    border-bottom: none;
}

.senha-modal .s-header p {
    font-weight: 600;
    color: #505050;
}

#senha-modal-id {
    max-width: 467px;
}

.s-body{
    padding-left:5%;
    padding-right:5%;
}

#senha-modal-id .input-register-login-facj{
    border-color: #505050;
}

#senha-modal-id .input-register-login-facj::placeholder{
    color: #505050 !important;
}

#senha-modal-id .form-control:focus {
    color: #505050;
    background-color: white;
    outline: 0;
    box-shadow: none !important;
}