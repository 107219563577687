.loading-layer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.loading-layer .loading-text{
    margin-top: 20px;
    font-size: 18px;
    color: #fff;
}