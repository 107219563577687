.switch-container {
  display: flex;
  width: 200px;
  height: 50px;
 
  width: 100%;
  border-radius: 6px;
  background-color: var(--base_03);
}

.switch-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selected {
  background-color: var(--secundaria_02);
  border-radius: 4px;
}

.switch-option {
  color: #000;
}

.switch-option.selected {
  color: #fff;
}