.banner-container {
 
  overflow: hidden;
  align-items: center;
  
}



.avatar-container {
 
 margin-top: -6rem;
  
}



.cabecalho-perfil{
  padding-top: 5%;
}