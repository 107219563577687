.pcng-mask-cpf {
  text-align: left;
  color: #505050;
  padding: 0.5rem 2rem;
  width: 100%;
  border-radius: 0.5rem;
  border: solid 2px #f6c12c;
}

.balloon {
  position: absolute;
  bottom: -40px;
  left: 5px;
  background: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
  display: none;
}

.show-balloon {
  display: block;
}

.cg-error-api-tooltip{
  position: absolute;
  top: 0;
  /* z-index: 5; */
  display: block;
  /* min-height : 61px; */
  /* width: 100%; */
  padding: 0.3em .725em;
  font-weight: 500;
  margin-top: .1rem;
  font-size: 1.1em;
  line-height: 1.2em;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}
