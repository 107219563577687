.container-carousel-father{
   position:relative;
   width: 100vw;
}

.container-carousel{
    padding: 0;
    margin: 0 auto;
    width: 100vw;
    /* max-width: 1440px; */
    align-items: center;
    justify-content: center;
}

.container-carousel .container-fluid{
    padding: 0;
    margin: 0;
}

.container-carousel .row{
    width: 100%;
    margin: 0;
}

.container-carousel .d-flex{
    width: 100%;
    margin: 0;
}

.container-carousel .row .slide{
    width: 100%;
}

.container-carousel .d-flex{
    padding: 0;
}

.container-carousel .carousel-image{
    width: 100%;
}

.btn-carousel{
    align-items: start;
    justify-self: start;
    justify-content: start;
 
}

.texto-banner{
    display: flex;
    max-width: 29%;
    padding-bottom: 9%;
}

@media only screen and (max-width: 600px) {
    .texto-banner{
        
        max-width: 90%;
        
    }
  }