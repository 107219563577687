

 
.bg-radio {
    background-color:lightgray;
}


.overflow-historico{
    max-height: 26rem;
    overflow-y: auto
}