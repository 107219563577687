.bg-form{
    background-color: #dfdfdf9a;
}

.bg-border-line {
    border: 0.1px dotted #bebebe9f !important; 

}

.badge-style{
    align-items: baseline;
}
  

.badge-disp-red{
    background-color: red !important; 
}

.badge-disp-green{
    background-color: green !important; 
}