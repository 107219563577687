


.bg-login-adv{
 background-image: url('../../../../img/bg-login-adm.png');
 background-size: cover; 
 background-position: center; 
 background-repeat: no-repeat;

 height: 98vh;
 margin: 0;
 padding: 0;

}