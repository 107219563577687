/* Remove marcadores de lista (bullets) */
ul, ol {
  list-style: none;
}

/* Remove o espaçamento interno da lista não ordenada (ul) */
ul {
  padding: 0;
}

/* Remove o espaçamento interno e as margens externas da lista ordenada (ol) */
ol {
  padding: 0;
  margin: 0;
}

.item-menu{
 background-color: white;
  padding: 0.2rem 1.2rem;
  margin-bottom: 0.5rem;
}

.item-menu:hover{
  background-color: var(--primaria_01);

}
.item-menu:active {
  background-color: var(--secundaria_02);
  color: white !important;
}

.menu-subitem{
  background-color: rgba(253, 165, 0, 0.404);
  border-radius: 0.2rem;
}

/* s */

.texto-oculto {
  /* height: 1px; */
  overflow: hidden;
  transition: height 0.3s; /* Adicione uma transição para uma animação suave */
}

/* Defina a largura do menu quando estiver fechado */
.menu-fechado {
  /*verificar o uso da margem negativa*/
  margin-right: -11rem;
  max-width: 6rem;
  /* margin: 0; */
  padding: 0;
}

.icone-menu-container{
  width: 10px;
}







