.bg-titulo-extrato{
    background-color: var(--secundaria_02);
}

.cursor{
    cursor: pointer;
}

.extrato-container {
    max-height: 60vh; 
    overflow-y: auto; 
}
td {
    white-space: nowrap;
    overflow: hidden; /* Esconde qualquer conteúdo que ultrapasse a célula */
    text-overflow: ellipsis; 
}

.table-head-bg{
    background-color:#476BA9!important;
    font-size: 1rem;
    font-weight: 00;
}

.table-rounded-start{
    border-radius: 6px 0 0 6px;
}

.table-rounded-end{
    border-radius: 0 6px 6px 0;
}
d-flex {
    border-radius: 6px 0 0 6px;
}

.extrato-container .icon-text{
    padding-top: 1rem;
}

@media (max-width: 992px)
{
    .csv-export {
        margin-right: 0;
    }

    

    .extrato-container .icon-button{
        padding-top: 1.2rem;
        padding-left: 1rem;
    }
    
}
    

