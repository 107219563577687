@import '../../utils/colors.css';


/*botoes normais*/
.botao-primario{
    background-color: var(--secundaria_03) ;
    border: 2px solid var(--secundaria_03);
    color: white;
    font-family: Exo;
    padding: 0.6rem 4rem;
    display: block;
    font-weight: 600;
    
}

.botao-primario:active{
    background-color: var(--secundaria_03);
    border: 2px solid var(--secundaria_03);
    color: white;
    font-weight: 600;
}

.botao-primario:hover{
    background-color: var(--primaria_01);
    border: 2px solid var(--primaria_01);
    color: white;
    font-weight: 600;
}

/*----botao-secundario----*/

.botao-secundario{
    background-color: #476BA9;
    border: 2px solid #476BA9;
    color: white;
    font-family: Exo;
    padding: 0.6rem 4rem;
    display: block;
    font-weight: 600;
}

.botao-secundario-load{
    background-color: #476BA9;
    border: 2px solid #476BA9;
    color: white;
    font-family: Exo;
    padding: 0.6rem 4rem;
    display: flex;
    font-weight: 600;
    align-items:center;
    gap: 10px;
    justify-content: center;
}

.botao-secundario:active{
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    font-weight: 600;
}


.botao-secundario:hover{
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    font-weight: 600;
}

/*----botao-terciario----*/

.botao-terciario{
    background-color: #fff;
    border: 2px solid var(--base_02);
    font-family: Exo;
    padding: 0.6rem 4rem;
    display: block;
    color: var(--base_02);
    font-weight: 600;
}

.botao-terciario:active{
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    
    font-weight: 600;
}


.botao-terciario:hover{
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    
    font-weight: 600;
}

.botao-terciario {
    background-color: white;
    color: #bfbfbf;
    border: solid 2px #bfbfbf;
    padding: 0.6rem 4rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
}

.botao-terciario:active {
    background-color: grey ;
    border: 2px solid grey;
    color: white;
    
    font-weight: 600;
}


.botao-terciario:hover{
    background-color: #bfbfbf;
    border: 2px solid #bfbfbf;
    color: white;
    
    font-weight: 600;
}

/*----------------primary-x-------------------------*/
.botao-primario-X {
    padding: 0.2rem 0.60rem;
    display: block;
    font-family: Exo;
    font-size: 1rem;
    font-weight: 600;

    color: #bfbfbf;
    background-color: #fff;
    border: 2px solid var(--base_02);
   
}

.botao-primario-X:hover{
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    
}

/*-------------------------------------botoes SM------------------------*/
.botao-primario-sm{
    background-color: var(--secundaria_03) ;
    border: 2px solid var(--secundaria_03);
    color: white;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: block;
    font-weight: 600;
    
}

.botao-secundario-sm{
    background-color: #476BA9;
    border: solid 2px #476BA9;
    color: white;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: block;
    font-weight: 600;
    
}

.botao-terciario-sm {
    background-color: white;
    border: solid 2px #bfbfbf;
    color: #bfbfbf;
    padding: 0.2rem 1rem;
    display: block;
    font-size: 1rem;
    font-family: Exo;
    font-weight: 600;
}

/*----------------------------------------------------------*/


.botao-primario-numero {
    width: 6rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: Exo;
    color: var(--base_03);
    background-color: #fff;
    background-color: var(--secundaria_03) ;
    border: 2px solid var(--secundaria_03);
    text-align: center; 
    text-decoration: none; 
}

.botao-primario-numero:hover {
    background-color: var(--primaria_01);
    border: 2px solid var(--primaria_01);
    color: white;
    font-weight: 600;
}

.botao-secundario-numero {
    width: 6rem;
    height: 3rem;
    font-size: 1rem;
    font-family: Exo;
    font-weight: 600;
    color: var(--base_03);
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    text-align: center; 
    text-decoration: none; 
}

.botao-secundario-numero:hover {
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
    font-weight: 600;
}

.botao-terciario-numero {
    width: 6rem;
    height: 3rem;
    font-family: Exo;
    font-size: 1rem;
    font-weight: 500;
    color: #bfbfbf;
    background-color: #fff;
    border: 2px solid var(--base_02);
    text-align: center; 
    text-decoration: none; 
}

.botao-terciario-numero:hover {
    background-color: var(--secundaria_02);
    border: 2px solid var(--secundaria_02);
    color: white;
}

.botao-danger-sm{
    background-color: #ffffff ;
    border: 2px solid #DC3545;
    color:  #DC3545;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: block;
   
    font-weight: 500;
    max-width: 20rem;
}

.botao-danger-sm:hover{
    background-color:  #DC3545 ;
    border: 2px solid  #DC3545;
    color: white;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: block;
    font-weight: 600;
    
}

.botao-danger-sm-load{
    background-color: #ffffff;
    border: 2px solid #DC3545;
    color:  #DC3545;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: flex;
    align-items:center;
    gap: 10px;
    justify-content: center;
    
    font-weight: 500;
    max-width: 20rem;
}

.botao-danger-sm-load:hover{
    background-color:  #DC3545 ;
    border: 2px solid  #DC3545;
    color: white;
    font-family: Exo;
    padding: 0.2rem 1rem;
    display: flex;
    font-weight: 600;
}

