.sub-dropdown .dropdown-menu {
  border-color: #f6c12c;
  line-height: 2rem;
  max-height: 36vh;
  overflow-y: auto;
  z-index: 1;
}

.sub-dropdown .item-combo {
  color: #505050;
  cursor: pointer;
  padding: 0.3rem 2rem;
  border-radius: 0.2rem;
}

.sub-dropdown .item-combo:hover {
  background-color: #c9c9c993;
}

.sub-dropdown .dropdown-submenu {
  padding-left: 20px;
}
