.resposta-forum-border{
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    background-color: rgb(255, 255, 255);
    padding:  0.8rem;
    border-radius: 1.5rem 0.4rem 0.4rem 0;
    
    margin-right: auto; /* Adiciona esta linha para alinhar à direita */
    -webkit-box-shadow: 10px 10px 17px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 17px -16px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 17px -16px rgba(0,0,0,0.75);
    border: 2px solid #d8d8d89d;
    
  }

  .scrollable-card-container{
    overflow-y: scroll;
    max-height: 70vh;
  }

  .bg-pergunta{
    background-color: #f0f0f0;
    border-radius: 0.5rem;
  }