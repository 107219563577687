.form-forum{
    max-width: 600px;
    margin: 0 auto;
}

.paragrafo-fale-conosco {
    padding: 1rem 0 3rem 0;
}

.margim-campos-formulario {
    margin-bottom: 35px;
}