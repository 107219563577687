

.fluid-bg{
    padding: 0;
    margin: 0;
}

.custom-tabs .nav-link.active {
    border: solid 2px var(--secundaria_02); 
      background-color: var(--secundaria_02);
      color: var(--base_03);
      border-radius: 6px;
      font-weight: 600;
    
  }
      .custom-tabs .nav-link {
       border: solid 2px var(--base_02);
      background-color: var(--base_03); 
      color: var(--base_01);
      border-radius: 6px;
      font-weight: 500;  
    }
  
    .custom-tabs{
      border: none;
    }