
.linha-vertical-warning{

    border-left: 0.8rem solid #F6C12C;   
    position: absolute;
    
    margin-left: 50px;
    height: 100%;
    z-index: 1;

}

.index{
    position: relative;
    z-index: 10;
    padding-top: 4rem ;
    margin: 0;
}