.bolinhas-container {
  display: flex;
  width: 200px;
}

.bolinha {
  width: 20px;
  height: 20px;
  background-color: #BFBFBF;
  border-radius: 50%;
}

.destacada {
  background-color: #476BA9;
}