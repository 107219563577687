.dropdown-menu {
  border-color: #f6c12c;
  line-height: 2rem;
}

.drop-primario {
  text-align: left;
  color: #505050;
  padding: 0.5rem 2rem;
  width: 100%;
  border-radius: 0.5rem;
  border: solid 2px #f6c12c;
}

.align-search-input {
  display: flex;
  align-items: center;
  margin-inline: 10px;
}

.align-search-input input {
  flex: 1;
}

.align-search-input .anticon-close {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.drop-primario:hover {
  background-color: #c9c9c993;
}

.item-combo {
  color: #505050;
  cursor: pointer;
  padding: 0.3rem 2rem;
  border-radius: 0.2rem;
}

.item-combo:hover {
  background-color: #c9c9c993;
}

.custom-dropdown-menu {
  width: 100%;
  max-height: 36vh;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-submenu {
  padding-left: 20px;
}
