:root {
    --base_01: #505050;
    --base_02: #BFBFBF;
    --base_03: #F4F4F4;

    --primaria_01: #EEB20A;
    --primaria_02: #1C2A42;
    --primaria_grandiente: #90c640;

    --secundaria_01: #324B75;
    --secundaria_02: #476BA9;
    --secundaria_03: #F6C12C;
    --secundaria_04: #F8CD55;

}