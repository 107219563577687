.list{
  gap:rem ;
  font-size: 1rem;
  font-weight: 500;
  gap: 1.6rem;
  color: black !important;
  z-index: 7;

    
}

 .list.item{
        display: block;
         align-items: end;
         justify-content:space-between;
         
}


     
.no-toggle-icon::after {
  display: none !important;
}

      

   
