.bg-titulo-extrato{
    background-color: var(--secundaria_02);
}

.cursor{
    cursor: pointer;
}

.extrato-container {
    max-height: 60vh; 
    overflow-y: auto; 
}

.cor-texto-secundário h3{
    color: #fff;
}