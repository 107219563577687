/* Estilos para dispositivos móveis (largura máxima de 767px) */
@media (max-width: 767px) {
    /* Estilos para dispositivos móveis aqui */
    .video-container {
      position: relative;
      padding-bottom: 56%; /* Proporção personalizada para dispositivos móveis */
    }
    
    .video-container iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
  
  /* Estilos para desktops (largura mínima de 768px) */
  @media (min-width: 768px) {
    /* Estilos para desktops aqui */
    .video-container {
      position: relative;
      padding-bottom: 45%; /* Proporção 16:9 para desktops */
    }
    
    .video-container iframe {
      position: absolute;
      width: 90%;
      height: 100%;
    }
  }
  