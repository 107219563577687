.termo-scrol{
  overflow-y: scroll;
  max-height: 70vh;
}

.pf-mt-5{
  margin-top: 3rem;
}

.pf-mx-5 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.pf-px-5 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.pf-my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}


@media (max-width: 992px)
{

  .pf-mx-5 {
    margin-right: 0;
    margin-left: 0;
  }
  
  .pf-px-5 {
    padding-right: 0;
    padding-left: 0;
  }
}