/* :root div {
    margin: 0 auto;
} */

.viewport {
    position: relative;
    padding: 10vh 0 10vh;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    overflow-x: hidden;
    margin: 0 0 0 0;
}

.viewport-error {
    position: relative;
    padding: 5vh 0 10vh;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow-x: hidden;
}

.viewport-error p {
    color: #e2e2e2;
    font-size: 24px;
}

#video-container {
    position: relative;
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* flex-wrap: wrap; */
}

#user-videos {
    position: relative;
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.video-container-view {
    position: relative;
    display: flex;
    justify-content: center;
}

#share-container-view  {
    position: relative;
    display: flex;
    justify-content: center;
    width: 80vw;
    height: 70vh;
}

#other-video-container-view{
    position: relative;
    display: none;
    justify-content: center;
}

.my-self-view-canvas-avatar, #other-self-view-canvas-avatar, #principal-view-canvas-avatar, #side-video-view-canvas-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #888;
    border-radius: 8px;
    margin: 0 0 0 0;
}

/* #share-container-view {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #888;
    border-radius: 8px;
    margin: 0 0 0 0;
} */

#my-avatar, #other-avatar {
    font-size: 24px;
    font-weight: 700;
    color: #e2e2e2;
}

#video-container-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* #video-container {
    width: 700px;
    height: 500px;
    aspect-ratio: 16/9;
} */

.my-self-view-canvas, #other-self-view-canvas {
    width: 854px;
    height: 480px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

#screen-share-content-canvas {
    width: 854px;
    height: 480px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.content-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.content-buttons div {
    display: flex;
    gap: 8px;
}

.zm-volume-range {
    position: relative;
    margin-left: -5px;
}

.zm-volume-range input {
    margin:auto 0;
}

.zm-volume-range input[type=range][orient=vertical] {
    writing-mode: vertical-lr;
    direction: rtl;
    appearance: slider-vertical !important;
    width: 12px;
    height: 50px;
    vertical-align: bottom;
    top: 0;
    bottom: 0;
    border-radius: 20px;
    position: absolute;
    background-color: white;
}