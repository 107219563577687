.carousel-item-container {
    position: relative;
    width: 100vw;
    height: auto; /* Adjust height as needed */
  }
  
  .carousel-image {
    width: 100%;
    height: auto; /* Ensure the image scales correctly */
  }
  
  .carousel-caption {
    position: absolute;
    left: 15vw;
    text-align: center;
    color: #fff;
    padding: 10px;
    bottom: 15%;
    max-width: 35vw;/* Optional padding for better appearance */
  }

  .carousel-caption .btn {
    color: #1C2A42;
    background-color: #EEB20A;
  }

  .carousel-caption .subtext-banner{
    max-width: 90%;
  }
  

  
  .carousel-caption .carousel-btn:hover {
    background-color: #0056b3; /* Background color on hover */
  }

  .carousel-caption .subtext-banner{
    max-width: 85%;
  }

  @media only screen and (max-width: 1500px) {
    .carousel-caption {
      max-width: 30vw;
      font-size: 1rem; 
    }

    .carousel-caption .btn {
      margin-top: 10px;
      padding: 0.4rem 2.5rem;/* Adjust padding as needed */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 0.9rem; /* Font size */
      transition: background-color 0.3s ease; /* Smooth background color transition */
    }
  
    /* .carousel-caption h1,
    .carousel-caption h2,
    .carousel-caption button {
      font-size: 1rem; /* Ensure text and button scale down 
    } */
  }

  @media only screen and (max-width: 900px) {
    .carousel-caption {
      max-width: 30vw;
      font-size: 1rem; 
    }

    .carousel-caption .btn {
      margin-top: 10px;
      padding: 0.3rem 2rem;/* Adjust padding as needed */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 0.6rem; /* Font size */
      transition: background-color 0.3s ease; /* Smooth background color transition */
    }
  
    /* .carousel-caption h1,
    .carousel-caption h2,
    .carousel-caption button {
      font-size: 1rem; /* Ensure text and button scale down 
    } */
  }
  
  @media only screen and (max-width: 767px) {
    .carousel-caption {
      max-width: 40vw;
      font-size: 1rem; 
      bottom: 38%;
    }

    .carousel-caption .btn {
      margin-top: 10px;
      padding: 0.3rem 1.5rem;/* Adjust padding as needed */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 0.7rem; /* Font size */
      transition: background-color 0.3s ease; /* Smooth background color transition */
    }
  
    /* .carousel-caption h1,
    .carousel-caption h2,
    .carousel-caption button {
      font-size: 1rem; /* Ensure text and button scale down 
    } */
  }

  @media only screen and (max-width: 550px) {
    .carousel-caption {
      max-width: 45vw;
      font-size: 1rem; 
      bottom: 23%;
    }

    .carousel-caption .subtext-banner{
      max-width: 70%;
    }

    .carousel-caption .btn {
      margin-top: 10px;
      padding: 0.3rem 1.3rem;/* Adjust padding as needed */
      cursor: pointer; /* Pointer cursor on hover */
      font-size: 0.5rem; /* Font size */
      transition: background-color 0.3s ease; /* Smooth background color transition */
    }
  }
  

  @media only screen and (max-width: 400px) {
    .carousel-caption {
      max-width: 45vw;
      font-size: 1rem; 
      bottom: 15%;
    }
  }