@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "normalize.css";
@import "sanitize.css";
@import "normalize.css/opinionated.css";

* {
    font-family: Exo, sans-serif !important;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

img {
  border-style: none !important;
  
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-main-container{
  height: 82% !important;
}

/* .App-header{
  display: none !important;
} */




