.horario-btn{
    border: 0.18rem solid rgb(158, 158, 158);
    border-radius: 0.6rem ;
    align-items: center;
}

.horario-btn:hover{    
    cursor: pointer;
    border: 0.18rem solid #EEB20A;
     color: white!important;     
}

.bg-data{
    background-color: #F6C12C;   
}

.coluna-horarios{
    max-width: 20%;    
}

@media (max-width: 768px)
{
  .coluna-horarios{
    max-width: 100%;
    }
}