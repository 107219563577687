.fluid-bg{
    padding: 0;
    margin: 0;
}

.active>.page-link, .page-link.active {
    color: rgb(255, 255, 255) !important;
    background-color: #476BA9 !important;
    border-color: #476BA9!important;
}

.page-link {
color:#476BA9 !important;
}