.alinhamento-step {
      max-width: 75%;
}

@media (max-width: 767px) {
      .alinhamento-step {
        max-width: 95%;
      }
    }

.campo-overflow {
      overflow: scroll;
      max-width: 100%;
      height: 13rem;
}

.card-itens {
      border: 2px solid #BFBFBF;
      border-radius: 10px;
      padding: 0.3rem;
      font-size: 0.9rem !important;
}

.card-step-4 {
      max-width: 22rem;
      border: 0px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 2rem;
      border-radius: 25px;
}

.dropdown-index{
      z-index: 999;
}

.input-codigo{
      height: 5rem;
      text-align: center;
      font-size: 2.2rem;
      font-weight: 800;
      color: #476BA9!important; /* Use !important para garantir precedência */
}

.link-color{
      color: #476BA9;
}

.botao-sec-disable{
      background-color: #476BA9!important;
      border: 2px solid #476BA9!important;
      color: white !important;
      font-family: Exo !important;
      padding: 0.6rem 4rem!important;
      display: block!important;
      font-weight: 600!important;
  }