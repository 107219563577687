

.fluid-bg{
    padding: 0;
    margin: 0;
}

.extrato-container{
    max-width:  70%;
    margin: 0 auto;
}

@media (max-width: 768px)
{
    .extrato-container{
        max-width:  95%;
        margin: 0 auto;
    }
}