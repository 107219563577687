.list{
  gap:rem ;
  font-size: 1rem;
  font-weight: 500;
  gap: 1.6rem;
  color: black !important;
  z-index: 7;

    
}

 .list.item{
        display: block;
         align-items: end;
         justify-content:space-between;
         
}
   
.no-toggle-icon::after {
  display: none !important;
}

.container {
  position: relative;
}

.number-badge {
  /* text-align: center; */
  display: flex;
  justify-content: center;
 align-items: center;
  width: 22px;
  height: 22px;
  position: absolute;
  top: -6px;
  right: 8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 4px; 
  font-size:10px;
}     

   
