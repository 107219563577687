.ag-steps-div{
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #F4F4F4;
    border: 1px solid #BFBFBF;
}
.ag-active .ag-steps-div{
    background-color: #F6C12C;
}
.ag-left{
    padding-right: 4vw;
}

.ag-right{
    padding-left: 4vw;
}

.ag-center{
    padding-right: 2vw;
    padding-left: 2vw;
}

.ag-steps-div p{
    margin: auto 0px;
    font-family: Exo;
    font-size: 1.56em;
    font-weight: 500;
    line-height: 2.07em;
    text-align: center;

}

.ag-step-resumo {
    font-weight: 500;
    line-height: 1.79em;
    text-align: justify;
    background-color: #F4F4F4;
    padding: 1.79em 2em 1.79em 0;
    border-radius: 10px;
    margin: 0;
}

.resumo-bold {
    font-weight: 600;
    line-height: 1.79em;
}

.pg-step-buttons button{
    min-width: 50%;
    border-radius: 4px;
}

#justify-tab-example-tab-Particular {
    border-radius: 6px 0 0 6px;
    padding-right: 0;
    margin-right: 0;
    border-right-width: 1px;
    font-weight: 600;
}

#justify-tab-example-tab-Consignado {
    border-radius: 0 6px 6px 0;
    padding-left: 0;
    margin-left: 0;
    border-left-width: 1px;
    font-weight: 600;
}

@media (max-width: 1192px)
{

    .ag-step-resumo img {
        display: none;
    }
}

@media (max-width: 992px)
{
    .pg-step-buttons button {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .ag-step-resumo img {
        display: none;
    }

    .ag-left{
        padding-right: 2vw;
        padding-left: 2vw;
    }
    
    .ag-right{
        padding-right: 2vw;
        padding-left: 2vw;
    }
    
    .ag-center{
        padding-right: 2vw;
        padding-left: 2vw;
    }

    .ag-steps-div {
        width: 100%;
    }
}
