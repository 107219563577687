.placeholder-modal {
      max-width: 22rem;
      border: 0px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 2rem;
      border-radius: 25px;
}

div {
      margin: 5px 0;
}