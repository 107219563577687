.bg-chat {
  background-color: rgb(236, 236, 236);
}

.clickable-avatar {
  cursor: pointer;
}

.advogados-list {
  max-height: 70vh;
  overflow-y: auto;
}

.chat-list {
  max-height: 55vh;
  overflow-y: auto;
}

.selected-advogado {
  background-color: rgb(224, 224, 224);
  padding-bottom: 0.4rem;
  border-radius: 0.2rem 0 0 0.2rem;
}


.cliente-chat .mensagem-cliente {
  font-size: 1rem;
  font-weight: 500;
  text-align: right;
  background-color: white;
  padding: 0.8rem;
  border-radius: 1.5rem 0.4rem 0.4rem 0;
  max-width: 70%;
  margin-left: auto;
  -webkit-box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  border: 2px solid #d8d8d89d;
  ;
}

.cliente-chat .data-cliente {
  text-align: right;
  margin-left: auto;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  padding-right: 1rem;

}

.cliente-chat .mensagem-advogado {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  background-color: rgb(255, 255, 255);
  padding: 0.8rem;
  border-radius: 0.4rem 1.5rem 0 0.4rem;
  max-width: 80%;
  margin-right: auto;
  /* Adiciona esta linha para alinhar à direita */
  -webkit-box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 17px -16px rgba(0, 0, 0, 0.75);
  border: 2px solid #a8badab0;

}

.cliente-chat .data-advogado {
  text-align: left;
  margin-right: auto;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  padding-left: 1rem;
}


.notification-chat {
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  margin-top: -10px;
  position: absolute;
  top: 0.8rem;
  right: 2.4rem;
 font-size: 10px;
 padding-top: 0.2rem;
 font-weight: bold;
 font-size: 0.75rem;
}

.notification-relative {

  position: relative;
}