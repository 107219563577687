.text-banner{
    color: rgb(255, 255, 255);
    line-height: 3.2rem;
    font-size: 2.8rem;
    font-weight: 700;
    text-align: left;
    
    margin: 0;
}

@media only screen and (max-width: 1500px) {
    .text-banner{
        width: 100%;
        line-height: 2.4rem;
        font-size: 1.8rem;
        width: 100%;
    }
}


@media only screen and (max-width: 800px) {
    .text-banner{
        width: 100%;
        line-height: 1.8rem;
        font-size: 1.2rem;
        width: 100%;
    }
  }


  @media only screen and (max-width: 500px) {
    .text-banner {
        font-size: 1rem;
        line-height: 1.6rem;
        width: 100%;
        margin: 1rem 0;
    }
}
