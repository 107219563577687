.table-container {
    overflow-x: auto;
    
      }
  
td {  
    white-space: preserve nowrap;
    overflow: hidden; /* Esconde qualquer conteúdo que ultrapasse a célula */
    text-overflow: ellipsis; 
  }
  