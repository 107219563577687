.senha-col-btn button, .senha-confim-btns button {
    width: 100%;
    padding: 0.6rem 2rem;
    height: 55px;
}

.pl-senha {
    padding-left: 0.5rem;
}

.pr-senha {
    padding-right: 0.5rem;
}

.senha-col-btn {
    padding-bottom: 0;
    margin-bottom: 0;
}
