.img-banner {
 
  max-width: 100%;
 
}

@media (max-width: 768px) {
  .img-banner {
    max-width: 100%;
    display: block;
  }
}
