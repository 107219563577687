.banner-image-container {
  position: relative;
  padding: 0;
  margin: 0;  
  
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0% 10%;
}



.banner-col {
  padding: 0;
  margin: 0;
  
}

@media screen and (max-width: 768px) {
  .banner-image-container {
   
    height:26rem;
    object-fit: contain;
  }
}
 