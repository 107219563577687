.imagem-div-login-2 {
    width: 100%;
    height: 100vh;
    object-fit:cover;
    padding: 0;
    margin: 0;
    border: 0;
}


