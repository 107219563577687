.input-register-login-facj {
    margin: 0px 0;
    border: 0;
    border-bottom: solid 3px var(--base_02);
    border-radius: 0;
    display: flex;
    position: relative;
}

.icon-password-facj {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    margin-right: 20px;
}

.container-register-facj {
    padding: 0 6vw;
    text-align: left;
    margin-top: 8vh;
    color: var(--base_02);
}


.btn-register-facj {
    display: flex;
    justify-content: space-between;
}

.navbar-register-facj {
    padding: 2rem 0 5rem 0;
}

.btn-register-facj-1{
    display: grid;
}


.progress-bar{
    height: 100%;  
    padding:  0;
    margin: 0;
    background-color: #476ba9;
    font-size: 12px;
    font-weight: 600;
    padding-top: 3px;
}

.progress{
    height: 1.2rem;
    padding:  0;
}