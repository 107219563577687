.subtext-banner{
    color: rgb(255, 255, 255);
    line-height: 1.8rem;
    text-align: left;
    font-weight: 500;
    font-size: 1.5rem;
    
    margin: 3rem 0;
    
}



  @media only screen and (max-width: 1500px) {
    .subtext-banner{
        width: 100%;
        line-height: 1.2rem;
        font-size: 1rem;
        width: 100%;
        margin: 1.5rem 0;
    }
}


@media only screen and (max-width: 900px) {
    .subtext-banner {
        font-size: 0.7rem;
        line-height: 0.9rem;
        width: 100%;
        margin: 1rem 0;
    }
  }


@media only screen and (max-width: 500px) {
    .subtext-banner {
        font-size: 0.6rem;
        line-height: 0.8rem;
        width: 100%;
        margin: 1rem 0;
    }
}