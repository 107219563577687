.intervalo-semana {
  max-width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
}

.coluna-horarios {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.bg-data {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f6c12c;
  border-radius: 5px;
  padding: 5px;
}

.horario-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border: solid rgb(158, 158, 158);
  border-radius: 0.5rem;
}

.horario-btn:hover {
  cursor: pointer;
  border: solid #eeb20a;
  color: white !important;
}

.scrollable-modal-body {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 20px;
}
