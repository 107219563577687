.box-adv-busca {
  max-width: 85%;
  margin: 0 auto;
}

.badge-bg {
  background-color: #476BA9 !important;
}

.underline-link{
  border-bottom: solid 2px #FFC107;
}

.horarios-disp button {
  width: 100% !important;
}

.plan-flag{
  border: solid 1px #FFC107;
  color: #FFC107;
}
