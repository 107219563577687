/* .bg-aviso-box{
    background-color: #e9e9e9c7 !important;
} */

.bg-error-api-tooltip{
    position: absolute;
    top: 0;
    /* z-index: 5; */
    display: block;
    /* max-width: 100%; */
    padding: 0.7em .625em;
    font-weight: 500;
    margin-top: .1rem;
    font-size: 1.1em;
    line-height: 1.827em;
    color: #fff;
    background-color: var(--bs-danger);
    border-radius: var(--bs-border-radius);
}

.pl-0{
    padding-left: 0 !important;
}

.pr-3{
    padding-right: 3rem !important;
}