@import "rsuite/dist/rsuite.css";

.calendar-todo-list {
    text-align: left;
    padding: 0;
    list-style: none;
  }
  
  .calendar-todo-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .calendar-todo-item-badge {
    vertical-align: top;
    margin-top: 8px;
    width: 6px;
    height: 6px;
  }  

  .bg-calendar{
    background-color: rgba(230, 230, 230, 0.445);
  }


  .badge-color{
    background-color: #476BA9!important;
  }

  .badge-size{
    padding: 1.8px 6px ;
    font-size: 12px;
    font-weight:600;
    letter-spacing: 0.6px;

  }
