.table thead tr th {
  vertical-align: middle;
  text-align: center;
}

.table th input,
.table th select {
  width: 100%;
  height: 30px;
  padding: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.exportar-button {
  position: absolute;
  top: 5%;
  right: 0;
}
