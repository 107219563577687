.dropdown-menu{
    border-color: #F6C12C;
    
    
    line-height: 2rem;
}

.drop-primario{
    text-align: left;
    color: #505050;
    padding: 0.5rem 2rem;
    width: 100%;
    border-radius: 0.5rem;
    border: solid 2px #F6C12C;
  
}

.drop-primario:hover{
    background-color: #c9c9c993;
}

.item-combo{
    color:  #505050;
    cursor: pointer;
    padding: 0.3rem 2rem;
    border-radius: .2rem;
}

.item-combo:hover{
    background-color:  #c9c9c993;

}

.custom-dropdown-menu {
    width: 100%;
    max-height: 36vh;
    overflow-y: auto;
    z-index: 1;
  }