.avatar-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.avatar-size-g {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 7rem;
  font-weight: 700;
  z-index: 1;
}

.avatar-size-p {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#fff;
  font-size: 2.2rem;
  font-weight: 800;
  z-index: 1;
}

@media (max-width: 600px)
{
  .avatar-size-p {
    
  display: none;
  }
}

.first-letter{
  
  display: none;
}
